'use client';

import { Container } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/react';
import { useEffect } from 'react';

import Promotions from '@/app/_components/promotions/Promotions';
import { useFloatingCTA } from '@/hooks/floating-cta.hook';
import { getLeadTypeForSpecial } from '@/services/specials/specials.service';
import { SpecialDetails } from '@/services/SpecialsService.service';
import type { FeaturedItem } from '@/types/home/home.types';
import { Range } from '@/types/request/request.types';

import BannerSection from './BannerSection';
import Overview from './Overview';
import Variants from './Variants';

interface Props {
  promotionsItems: FeaturedItem[];
  specialDetails: SpecialDetails;
  ranges: Range[];
}

const PromotionsDetails = ({
  promotionsItems,
  specialDetails,
  ranges,
}: Props) => {
  const { setFormItem } = useFloatingCTA();

  const [isDesktop] = useMediaQuery('(min-width: 480px)');

  useEffect(() => {
    const leadType = getLeadTypeForSpecial(specialDetails);

    setFormItem({
      type: 'special',
      special: specialDetails,
      leadType: leadType,
    });
    return () => setFormItem(undefined);
  }, [specialDetails, setFormItem, isDesktop]);

  return (
    <>
      {specialDetails ? (
        <>
          <BannerSection
            specialDetails={specialDetails}
            imageKeys={{
              desktopKey: 'image-1440-680',
              mobileKey: 'image-430-305',
            }}
          />
          <Container maxW={'1280'} width={'100%'}>
            {specialDetails && specialDetails?.content_blocks?.length && (
              <Overview specialDetails={specialDetails} />
            )}
            <Variants specialDetails={specialDetails} />
          </Container>
        </>
      ) : (
        <Container maxW={'1280'} width={'100%'}>
          <Box>Promotion not found</Box>
        </Container>
      )}

      <Promotions
        isShowNavigation
        featuredItems={promotionsItems}
        sectionTitle="Recommended Promotions"
        ranges={ranges}
      />
    </>
  );
};

export default PromotionsDetails;
