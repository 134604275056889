'use client';
import { Box, Container, Flex, Heading } from '@chakra-ui/layout';
import cn from 'classnames';
import React, { useRef, useState } from 'react';
import slugify from 'react-slugify';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import PromotionsCard from '@/app/_components/promotions-card/PromotionsCard';
import { ReactComponent as ArrowBackwardCircle } from '@/app/_icons/ArrowBackwardCircleOutline.svg';
import { ReactComponent as ArrowForwardCircle } from '@/app/_icons/ArrowForwardCircleOutline.svg';
import { getImageLink } from '@/services/link.service';
import { FeaturedItem } from '@/types/home/home.types';
// eslint-disable-next-line import/order
import { NewCarOverview } from '@/types/new-cars/new-cars.types';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './Promotions.scss';

import { Range } from '@/types/request/request.types';

type Props = {
  isShowNavigation?: boolean;
  isShowTiles?: boolean;
  featuredItems: FeaturedItem[];
  newCarsWithOverview?: NewCarOverview[];
  sectionTitle?: string;
  isButtonVisible?: boolean;
  ranges: Range[];
};

const Promotions = ({
  featuredItems,
  isShowNavigation = false,
  isShowTiles = false,
  sectionTitle = '',
  ranges,
}: Props) => {
  const swiperRef: any = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  function getModel(id: number): string | null {
    let model = ranges.findIndex((range) => range.id === id);

    if (!model || !id) {
      return null;
    }
    return slugify(ranges[model].name);
  }

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const CustomNavigation = () => {
    return (
      <Box className="swiper-navigation">
        <button
          onClick={goPrev}
          name="Previous"
          className="swiper-button-prev-1"
          role="button"
        >
          <ArrowBackwardCircle aria-label="Previous" />
        </button>
        <button
          onClick={goNext}
          name="Next"
          className="swiper-button-next-1"
          role="button"
        >
          <ArrowForwardCircle aria-label="Next" />
        </button>
      </Box>
    );
  };

  return (
    <>
      <Box className="promotionsWrapper">
        <Container
          maxW="100%"
          className="promotionsHeader"
          textAlign={'center'}
          marginBottom={{ base: 10, md: 20 }}
        >
          <Box className="headingElement" mb={'8!important'} px={'0!important'}>
            <Heading
              as="h2"
              className="titleLg"
              color={'white'}
              marginBottom={6}
            >
              {sectionTitle}
            </Heading>
          </Box>
          {isShowTiles && (
            <Flex
              justifyContent={{ base: 'center' }}
              flexWrap={'nowrap'}
              overflowX={'auto'}
              paddingLeft={{ base: 0 }}
            >
              <ButtonCustom
                btnText="All Kia’s"
                className="btnGreyOutlineDark"
                moduleClass="outlineWhite"
                extraClass="active"
              />
              <ButtonCustom
                btnText="New"
                className="btnGreyOutlineDark"
                moduleClass="outlineWhite"
              />
              <ButtonCustom
                btnText="Used"
                className="btnGreyOutlineDark"
                moduleClass="outlineWhite"
              />
            </Flex>
          )}
        </Container>
        <Swiper
          className="promotionsCarousel"
          ref={swiperRef}
          slidesPerView={3}
          spaceBetween={40}
          modules={[Pagination, Navigation]}
          pagination={{
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: function (number) {
              return number.toString();
            },
          }}
          navigation={{
            nextEl: '.swiper-button-next-1',
            prevEl: '.swiper-button-prev-1',
          }}
          onSlideChange={(swiper) => {
            setCurrentPage(swiper.realIndex + 1);
          }}
          onSwiper={(swiper) => {
            setTotalPages(swiper.slides.length);
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 'auto',
              spaceBetween: 15,
            },
            640: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 2,
            },
            1025: {
              slidesPerView: 3,
            },
          }}
        >
          {featuredItems &&
            featuredItems?.map((item, index) => (
              <SwiperSlide key={index}>
                <PromotionsCard
                  item={{ ...item, image: getImageLink(item?.image) ?? '' }}
                  key={item.id}
                  hrefBase={getModel(item?.range?.id) ? `/promotions/${getModel(item.range.id)}` : `/promotions`}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        {featuredItems.length > 1 && (
          <Box
            className={cn(
              'promotionsSwiperControls',
              isShowNavigation && 'showNavigation',
            )}
          >
            <Box className="swiper-pagination">
              <span className="current-page">{currentPage}</span>
              <span className="separator">/</span>
              <span className="total-pages">{totalPages}</span>
            </Box>

            <CustomNavigation />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Promotions;
